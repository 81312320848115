<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/Page-header";
import Uploader from "@/components/Posts/uploader";

/**
 * Starter component
 */
export default {
  page: {
    title: "Upload post",
  },
  data() {
    return {
      title: "Posts",
      items: [
        {
          text: "Work center",
          href: "/home",
        },
        {
          text: "Posts",
          href: "/posts/posts",
        },
        {
          text: "Create",
          active: true,
        },
      ],
      type: null,
    };
  },
  components: {
    Layout,
    PageHeader,
    Uploader,
  },
  methods:{
    handleSubmit() {
        this.$router.push(`/posts/posts`)
    },

  },
  async created(){
    this.type = 'create';
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="card">
          <div class="card-body">
            <div>
                <div>
                  <div>
                    <h2 v-if="type == 'create'">Upload Post Image</h2>
                    <hr>
                  </div>
                <form action="#" @submit.prevent="handleSubmit">
                    <Uploader/>
                </form>
                </div>
                <hr />
                <div class="float-right">
                <button class="btn btn-outline-success" type="submit" @click="handleSubmit" v-if="type == 'create'">Done</button>
                <b-button variant="outline-danger" class="ml-2 aline-right" @click="$router.go(-1)"
                >Cancel</b-button
                >
                </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
#input-group-1 {
  display: inline;
}
</style>